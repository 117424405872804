import React from "react";
import CustomContainer from "../CustomContainer";

import { Box, Typography, Container } from "@mui/material";
import Pricing_plain_1 from "../../assets/new/pricing_plain_1.gif";
import NewPlan from "./NewPlan";
import { Link } from "react-router-dom";
function PricingPlan() {
  return (
    <CustomContainer>
      <Container>
        <Box
          sx={{
            textAlign: "center",
            py: 6,
            color: "#17233D",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "800",
              color: "var(--black)",
              fontSize: { xs: "30px", sm: "35px", md: "40px", lg: "45px" },
              lineHeight: {
                xs: "40px",
                sm: "40px",
                md: "45px",
                lg: "50px",
              },
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Build Your Pricing Plan
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: "gray", mb: 4, fontSize: "16px" }}
          >
            Give your Customers the Content Experience they Deserve!

          </Typography>

          <Box
            sx={{
              borderRadius: "16px",
              maxWidth: "1000px",
              margin: "auto",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              boxShadow: 3,
              padding: "1rem",
            }}
          >
            <img
              src={Pricing_plain_1}
              alt="pricing Stack"
              style={{ width: "100%" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "3rem",
          }}
        >
          <Typography
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: "var(--text-gray)",
              paddingY: "2rem",
              maxWidth: "800px",
            }}
          >
            Contact us to explore the right pricing plan for your business and
            see how metR can streamline your content transformation.
          </Typography>
          <Link
            to={"/contact-us"}
            className="requestButton"
            style={{ zIndex: "1" }}
          >
            <Box
              component={"span"}
              sx={{
                color: "var(--white)",
                display: "inline-block",
                borderRadius: "100px",
                maxHeight: "40px",
                background: "var(--secondary)",
                padding: "12px 1.2rem",
                fontWeight: "400",
                fontSize: "15px",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "var(--text2)",
                },
              }}
            >
              Contact Us
            </Box>
          </Link>
        </Box>
        <Box sx={{ marginBottom: "3rem" }}>
          <NewPlan />
        </Box>
      </Container>
    </CustomContainer>
  );
}

export default PricingPlan;
