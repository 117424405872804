import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import sectionHeroHeader from "../assets/home/metR website infographic.gif";
import { Link } from "react-router-dom";
import RequestDemo from "./RequestDemo";

function Banner() {
  const theme = useTheme();

  return (
    <Container>
      <Box
        component={"div"}
        sx={{
          paddingTop: "2rem",
          paddingBottom: { xs: "2rem", md: "6rem", lg: "6rem" },
          [theme.breakpoints.up("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",

            [theme.breakpoints.down("md")]: {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              overflow: "hidden",
              position: "relative",
              height: "100%",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                marginTop: "5rem",
                fontWeight: "600",
                color: "var(--black)",
                fontSize: {
                  xs: "25px",
                  sm: "30px",
                  md: "43px",
                  lg: "40px",
                },

                lineHeight: {
                  xs: "40px",
                  sm: "40px",
                  md: "45px",
                  lg: "50px",
                },

                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                  marginTop: "2rem",
                },
              }}
            >
              Seamlessly Convert Unstructured Content into Intelligent
              AI-Enabled DITA Documents
            </Typography>
            <Typography
              sx={{
                marginTop: "24px",
                fontSize: "20px",
                color: "var(--text-new)",
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              }}
            >
              Empower Your Content with AI-driven Structuring, Enrichment, and Delivery.
            </Typography>
            <Box
              sx={{
                marginTop: "2rem",
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                  justifyContent: "center",
                  marginBottom: "2rem",
                },
              }}
            >
              <Link
                to={"/request-demo"}
                className="requestButton"
                style={{ zIndex: "1" }}
              >
                <RequestDemo />
              </Link>
              {/* <Box
                sx={{
                  marginLeft: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={playIcon}
                  style={{
                    height: "100%",
                    maxWidth: "40px",
                    marginRight: "10px",
                  }}
                  alt=""
                  srcSet=""
                />
                <span
                  style={{
                    backgroundImage: "var(--gradient-new2)",
                    color: "transparent",
                    backgroundClip: "text",
                  }}
                >
                  How It Works
                </span>
              </Box> */}
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img src={sectionHeroHeader} alt="" srcSet="" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Banner;
