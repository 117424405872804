import React from "react";
import { Box, Container, Typography } from "@mui/material";
import CustomContainer from "../CustomContainer";
import { Grid, CardContent } from "@mui/material";
import cardicon from "../../assets/Integrate/copy-writing.png";
import eyeglass from "../../assets/Integrate/content-management-system.png";
import icon2 from "../../assets/Integrate/enterprise.png";

const contentData = [
  {
    title: "Content Managers",
    description:
      "Gain full control over large documentation sets. Consolidate scattered files into a single source of truth with a structured, standardized format for easier updates and governance.",
    icon: eyeglass,
    textColor: "#F9F9F9",
    bgColor: "transparent",
    border: "none",
  },
  {
    title: "Technical Writers",
    description:
      "Simplify structured authoring and eliminate formatting hassles. Focus on creating high-quality documentation while metR handles content modeling and automated conversion.",
    icon: cardicon,
    textColor: "#F9F9F9",
    bgColor: "transparent",
    border: "none",
  },
  {
    title: "Enterprises",
    description:
      "Scale your content migration and modernization seamlessly. metR processes high volumes of documentation, ensuring compliance with DITA-XML and global content delivery standards.",
    icon: icon2,
    textColor: "#F9F9F9",
    bgColor: "transparent",
    border: "none",
  },
];

function Integrate() {
  return (
    <CustomContainer>
      <Box
        sx={{
          color: "white",
          py: 5,
          backgroundImage: "var(--gradient-new2)",
        }}
      >
        <Container>
          <Grid
            container
            spacing={3}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {/* Column 1 */}
            <Grid
              item
              xs={12}
              md={6}
              lg={4.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                fontWeight="600"
                sx={{
                  fontSize: { xs: "25px", sm: "30px", md: "43px", lg: "40px" },
                  lineHeight: {
                    xs: "40px",
                    sm: "40px",
                    md: "45px",
                    lg: "50px",
                  },
                }}
              >
                Designed for Those Who Drive Content Excellence
              </Typography>
            </Grid>

            {/* Dynamic Content Columns */}
            {contentData.map((item, index) => (
              <Grid key={index} item xs={12} md={6} lg={2.5}>
                <CardContent
                  sx={{
                    borderRadius: "32px",
                  }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={{ xs: "center", md: "flex-start" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "36px",
                        height: "36px",
                        background: "var(--secondary)",
                        borderRadius: "100%",
                        marginBottom: "5px",
                      }}
                    >
                      <img
                        src={item.icon}
                        alt="icon"
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    variant="h6"
                    fontWeight="600"
                    sx={{ color: item.textColor, fontSize: "20px" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: 1,
                      fontSize: "14px",
                    }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </CustomContainer>
  );
}

export default Integrate;
