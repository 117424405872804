import React from "react";
import { useTheme } from "@mui/material";
import CustomContainer from "../components/CustomContainer";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";

import Doc_1 from "../assets/solution/content migration.gif";
import Doc_2 from "../assets/solution/content optimization.gif";
import Doc_3 from "../assets/solution/content delivery.gif";
import Doc_4 from "../assets/solution/AI enablement.gif";
import arrow from "../assets/solution/arrow-down.png";

const steps = [
  {
    id: 1,
    title: "Content Migration",
    description:
      "Transform unstructured content into structured DITA-XML with AI-driven precision. metR ensures accurate content parsing, intent preservation, and seamless multi-channel publishing—eliminating manual migration errors.",
    image: Doc_1,
    reverse: false,
  },
  {
    id: 2,
    title: "Content Optimization",
    description:
      "Leverage AI to structure and enrich content with intelligent tagging and metadata. metR enhances searchability, standardizes taxonomy, and builds machine-readable content for advanced search and analytics.",
    image: Doc_2,
    reverse: true,
  },
  {
    id: 3,
    title: "Content Delivery",
    description:
      "Through our continuous delivery platform, metR automates the publishing of intent-driven content directly to hosting servers. Leveraging AI-enabled tagging, it enriches metadata and categorizes content based on context—such as titles, headings, product versions, and regions—enabling large language models (LLMs) to interpret, retrieve, and respond to user queries accurately.",
    image: Doc_3,
    reverse: false,
  },
  {
    id: 4,
    title: "AI Enablement",
    description:
      "metR powers intelligent content delivery by integrating AI-driven metadata enrichment and dynamic content categorization. Designed to work seamlessly with AI chatbots for all product versions and categories. This structured metadata feeds into a knowledge graph, enabling the chatbot to deliver precise, intent-aware responses and enhance the overall content retrieval experience.",
    image: Doc_4,
    reverse: true,
  },
];

function SolutionPage() {
  const isMediumScreen = useMediaQuery("(max-width: 968px)");
  const theme = useTheme(); // Get theme for breakpoints
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <CustomContainer>
      <Container
        sx={{
          maxWidth: "1250px !important",
          marginTop: "4rem",
          marginBottom: "6rem",
        }}
      >
        <Box mb={{ xs: "2rem", md: "4rem" }}>
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontWeight: "700",
              color: "var(--black)",
              marginX: "auto",
              maxWidth: "900px",

              fontSize: {
                xs: "25px",
                sm: "30px",
                md: "43px",
                lg: "40px",
              },

              lineHeight: {
                xs: "40px",
                sm: "40px",
                md: "45px",
                lg: "50px",
              },
            }}
          >
            Transforming Content, Enabling Intelligence
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              color: "var(--text-gray)",
              paddingY: "2rem",
            }}
          >
            Optimized, Structured, and AI-Enhanced Content—All in One Place
          </Typography>
        </Box>

        {steps.map((step, index) => {
          const isReversed = step.reverse;
          const shouldReverseOnMediumScreen =
            isMediumScreen && (index === 1 || index === 3);

          return (
            <React.Fragment key={step.id}>
              {/* Step Content */}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Grid
                  container
                  spacing={4}
                  alignItems="center"
                  sx={{ maxWidth: 900, paddingY: { xs: "3rem", md: "0" } }}
                >
                  {isReversed && !shouldReverseOnMediumScreen ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        justifyContent="center"
                      >
                        <img
                          src={step.image}
                          alt={step.title}
                          style={{
                            maxHeight: "350px",
                            width: "auto",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight="bold">
                          {step.id}. {step.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          mt={1}
                        >
                          {step.description}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6}>
                        <Typography
                          sx={{ textAlign: { xs: "center", md: "left" } }}
                          variant="h5"
                          fontWeight="bold"
                        >
                          {step.id}. {step.title}
                        </Typography>
                        <Typography
                          sx={{ textAlign: { xs: "center", md: "left" } }}
                          variant="body1"
                          color="text.secondary"
                          mt={1}
                        >
                          {step.description}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        display="flex"
                        justifyContent="center"
                      >
                        <img
                          src={step.image}
                          alt={step.title}
                          style={{
                            maxHeight: "300px",
                            width: "auto",
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>

              {/* Arrow Between Steps (Except the Last One) */}
              {index < steps.length - 1 && isLargeScreen && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={arrow}
                    alt="Arrow Down"
                    style={{
                      height: "80px",
                      maxWidth: "200px",
                      transform: index === 1 ? "scaleX(-1)" : "none",
                    }}
                  />
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </Container>
    </CustomContainer>
  );
}

export default SolutionPage;
