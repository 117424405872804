import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomContainer from "../CustomContainer";

const faqContent = [
  {
    title: "General Questions",
    description: `
        <h3>What is metR.io, and how does it help with content conversion?</h3>
        <p>metR.io is an advanced content conversion solution that automates transforming unstructured documents into structured formats such as DITA-XML. Our toolkit simplifies content management workflows, improves consistency, and enhances efficiency, allowing your business to scale effectively.</p>

        <h3>What document formats does metR support?</h3>
        <p>We support many document formats, including DOCX, HTML, Markdown, Flat Folio Files, DocBook, ArborText XML, JSON, FrameMaker, and PDF. This makes it easy to convert and manage content across multiple formats seamlessly while ensuring compatibility with DITA-XML.</p>

        <h3>What industries can benefit from metR?</h3>
        <p>metR serves industries such as finance, healthcare, manufacturing, software development, and technical documentation, ensuring domain-specific content compliance, consistency, and enriched metadata.</p>

        <h3>How does metR compare to other content migration tools?</h3>
        <p>metR stands out with AI-driven content parsing, metadata enrichment, and multi-format publishing while ensuring high accuracy and compliance with DITA standards. Unlike traditional conversion tools, it automates structuring, tagging, and taxonomy standardization for optimized AI-readability.</p>
    `,
  },
  {
    title: "Conversion Process & Features",
    description: `
        <h3>How does metR ensure high conversion accuracy?</h3>
        <p>metR uses AI-based parsing to analyze, structure, and convert content while preserving intent, context, and format integrity. Our solution eliminates manual intervention, reduces errors, and speeds up the conversion process.</p>

        <h3>Does metR retain formatting and document structure during conversion?</h3>
        <p>Yes, metR ensures that your documents retain their original formatting, structure, and data integrity. Our conversion algorithms produce high-quality output without sacrificing essential details.</p>

        <h3>How does metR handle images and multimedia content during conversion?</h3>
        <p>During conversion, metR extracts and organizes images, diagrams, and multimedia content into separate folders while maintaining references in the structured document for seamless integration.</p>

        <h3>Can metR normalize inconsistent document structures?</h3>
        <p>Yes, metR automates the standardization of headings, lists, tables, and other elements, ensuring uniformity across different document sets.</p>

        <h3>How does metR ensure metadata preservation?</h3>
        <p>metR applies AI-driven metadata extraction to retain critical information such as author names, version history, product names, and technical attributes, ensuring seamless information retrieval.</p>

        <h3>Can metR convert cross-references and hyperlinks?</h3>
        <p>Yes, metR validates and preserves cross-references and hyperlinks during conversion, ensuring all links are functional and correctly mapped in the converted output.</p>

        <h3>Does metR support structured content reuse?</h3>
        <p>metR breaks unstructured content into reusable components, allowing businesses to modularize content, reduce duplication, and enhance consistency across multiple documents or outputs.</p>

        <h3>How does metR optimize content for AI systems and search engines?</h3>
        <p>metR enriches content with intelligent metadata tagging, standardizes taxonomy, and builds structured content models that improve searchability and AI interpretability.</p>
    `,
  },
  {
    title: "Security & Compliance",
    description: `
        <h3>Is my content secure when using metR?</h3>
        <p>Yes, metR.io is ISO 27001:2022 certified, ensuring stringent data protection measures. All data transfers are encrypted, and files are securely stored in designated environments such as GitHub or cloud storage.</p>

        <h3>Does metR comply with industry standards?</h3>
        <p>Yes, metR ensures compliance with DITA, ISO, and GDPR regulations, making it suitable for organizations requiring structured, compliant content for audits and global delivery.</p>
    `,
  },
  {
    title: "Implementation & Support",
    description: `
        <h3>Who can use metR? Do I need technical expertise?</h3>
        <p>metR is designed for both technical and non-technical users. With an intuitive UI, teams can upload, convert, and manage content effortlessly, eliminating the need for advanced technical skills.</p>

        <h3>How can I get started with metR?</h3>
        <p>Getting started is easy! Contact our sales team for a demo and pricing details. We guide you through the setup process, ensuring seamless integration into your workflows.</p>

        <h3>What kind of support does metR offer?</h3>
        <p>We provide extensive documentation, training materials, and dedicated customer support to help businesses maximize the value of metR and resolve any issues efficiently.</p>
    `,
  },
  {
    title: "Scalability & Performance",
    description: `
        <h3>Can metR handle large-scale content migration projects?</h3>
        <p>Yes, metR is optimized for large-scale migrations. It uses batch processing to handle multiple documents simultaneously, reducing manual effort and increasing efficiency.</p>

        <h3>How fast is the conversion process?</h3>
        <p>metR is built for high-speed conversion, enabling businesses to process and transform large volumes of content quickly while maintaining precision.</p>
    `,
  },
  {
    title: "Information Architecture (IA) Solutions",
    description: `
        <h3>Does metR support Information Architecture (IA) for content portals?</h3>
        <p>Yes, metR engineers provide tailored IA solutions for enterprises that need structured content delivery through unified communication portals.</p>

        <h3>What IA challenges does metR address?</h3>
        <p>metR solves issues such as disorganized content repositories, inconsistent content formats, and scaling content for global teams by structuring content according to best IA practices.</p>

        <h3>How does metR benefit enterprises with large content repositories?</h3>
        <p>metR helps enterprises modularize content, streamline searchability, and ensure easy access across various knowledge bases and digital platforms.</p>
    `,
  },
];

function addDynamicNumbers(description) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = description;

  const headings = tempDiv.querySelectorAll("h3");
  headings.forEach((heading, index) => {
    heading.innerHTML = `${index + 1}. ${heading.innerHTML}`;
  });

  return tempDiv.innerHTML;
}

function FAQ() {
  return (
    <Box sx={{ marginBottom: "4rem" }}>
      <Box sx={{ background: "var(--gradient-color)", paddingY: "2rem" }}>
        <CustomContainer>
          <Container>
            <Typography
              align="center"
              sx={{
                fontSize: "40px",
                fontWeight: "700",
                color: "var(--text-primary)",
              }}
            >
              FAQs
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "var(--text-gray)",
                paddingY: "2rem",
              }}
            >
              Below are some frequently asked questions by our customers.
            </Typography>
          </Container>
        </CustomContainer>
      </Box>
      <CustomContainer>
        <Container>
          <Box sx={{ marginBottom: "2rem" }}>
            {faqContent.map((queAns, index) => (
              <Accordion
                key={index}
                sx={{
                  background: "var(--white)",
                  padding: "4px 16px",
                  marginBottom: "1rem",
                  borderRadius: "4px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                }}
                className="faq_accordion"
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: "var(--secondary)" }} />
                  }
                >
                  <Typography sx={{ fontSize: "17px", fontWeight: "600" }}>
                    {queAns.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: "0" }}>
                  <div
                    className="accordion_desc"
                    style={{ fontSize: "16px", fontWeight: "400" }}
                    dangerouslySetInnerHTML={{
                      __html: addDynamicNumbers(queAns.description),
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </CustomContainer>
    </Box>
  );
}

export default FAQ;
