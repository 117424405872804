import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Modal,
  Fade,
  Backdrop,
  Button,
} from "@mui/material";
import dataProcessing from "../assets/data_processing_yrrv.svg";

import CustomContainer from "../components/CustomContainer";
import { useTheme } from "@mui/material/styles";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import solBanner from "../assets/solBanner.png";

import ZohoForm from "../components/zohoForm";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "3px",
  outline: "none",
  p: 4,
};

function ContactUs() {
  const theme = useTheme();
  const contactBoxRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const query = useQuery();

  const planId = query.get("planId");

  useEffect(() => {
    const handleScrollToContactBox = () => {
      if (planId && contactBoxRef.current) {
        const headerHeight = 5 * 16;
        const scrollToPosition = contactBoxRef.current.offsetTop - headerHeight;

        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth",
        });
      }
    };

    handleScrollToContactBox();

    const handleUrlChange = () => {
      handleScrollToContactBox();
    };

    window.addEventListener("popstate", handleUrlChange);

    return () => {
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, [planId]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("submitted") === "true") {
      handleOpen();
    }
  }, []);

  return (
    <Box
      component={"div"}
      sx={{
        minHeight: "calc(100vh - 209.7px)",

        [theme.breakpoints.down("md")]: {
          minHeight: "100%",
        },
      }}
    >
      <CustomContainer>
        <Container>
          <Grid container sx={{ marginY: "2rem" }}>
            <Grid
              item
              md={12}
              className="background-image"
              style={{
                minHeight: "350px",
                maxHeight: "350px",
                width: "100%",
                borderRadius: "5px",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                backgroundSize: "cover",
                backgroundImage: `url(${solBanner})`,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
                }}
              ></div>
              <Typography
                component={"h1"}
                sx={{
                  zIndex: 1,
                  textAlign: "center",
                  maxWidth: "750px",
                  fontWeight: "700",
                  color: "var(--white)",
                  fontSize: {
                    xs: "22px",
                    sm: "25px",
                    md: "30px",
                    lg: "35px",
                  },

                  lineHeight: {
                    xs: "35px",
                    sm: "40px",
                    md: "45px",
                    lg: "47px",
                  },
                }}
              >
                {/* <Box
                  component={"span"}
                  sx={{
                    color: "var(--secondary)",
                    fontSize: {
                      xs: "43px",
                      sm: "48px",
                      md: "55px",
                      lg: "60px",
                    },
                  }}
                >
                  Demo
                </Box>{" "} */}
                Contact Us
              </Typography>
              <Typography
                sx={{
                  zIndex: 1,
                  marginY: "1rem",
                  lineHeight: "25px",
                  paddingX: "10px",
                  fontSize: "17px",
                  color: "var(--ourSol-card)",
                  fontWeight: "400",
                  textAlign: "center",
                  maxWidth: "900px",

                  [theme.breakpoints.down("lg")]: {
                    fontSize: "16px",
                  },
                }}
              >
                Get the right tool to migrate your unstructured content to
                DITA-XML for your enterprise.
              </Typography>
            </Grid>
          </Grid>

          <Box
            component={"div"}
            id="contactBox"
            ref={contactBoxRef}
            sx={{ marginY: "2rem" }}
          >
            <Grid
              container
              sx={{
                marginTop: "3rem",
                display: "flex",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                },
              }}
            >
              <Grid
                item
                md={6}
                sx={{
                  paddingRight: "1rem",
                  [theme.breakpoints.down("md")]: {
                    paddingRight: "0",
                    marginBottom: "2rem",
                  },
                }}
              >
                <Box>
                  <Box component={"div"}>
                    <ul
                      style={{
                        listStyleType: "none",
                        paddingLeft: "0",
                        marginTop: "0",
                      }}
                    >
                      <Box>
                        <img
                          src={dataProcessing}
                          alt=""
                          style={{
                            maxHeight: "180px",
                            marginBottom: "1rem",
                          }}
                        />
                        <Typography
                          variant="h2"
                          sx={{
                            fontSize: "18px",
                            color: "var(--text-primary)",
                            fontWeight: "400",
                            marginBottom: "2rem",
                          }}
                        >
                          In the technology driven era,{" "}
                          <span
                            translate="no"
                            style={{
                              color: "var(--text-hover)",
                              fontWeight: "bold",
                            }}
                          >
                            met
                          </span>
                          <span
                            translate="no"
                            style={{
                              color: "var(--secondary)",
                              fontWeight: "bold",
                            }}
                          >
                            R
                          </span>{" "}
                          by Metapercept, a utility tool guides you with the
                          best technical solution to content conversion,
                          implement content strategy, and delivery of your
                          content from unstructured to DITA-XML format.
                        </Typography>
                        <Box
                          sx={{
                            padding: "1rem",
                            border: "1px solid var(--border)",
                            borderRadius: "6px",
                            background: "var(--ourSol-card)",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <PhoneIcon
                              sx={{
                                color: "var(--button)",
                              }}
                            />
                            <Typography
                              sx={{
                                marginLeft: "10px",
                              }}
                            >
                              <a
                                className="underlined__atag"
                                href="tel:+91-839-090-5726"
                              >
                                +91-839-090-5726
                              </a>
                            </Typography>
                          </Box>
                          {/* <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginY: "0.5rem",
                            }}
                          >
                            <PhoneIcon
                              sx={{
                                color: "var(--button)",
                              }}
                            />
                            <Typography sx={{ marginLeft: "10px" }}>
                              <b>Line 2</b>:{" "}
                              <a
                                className="underlined__atag"
                                href="tel:+91-742-096-5726"
                              >
                                +91-742-096-5726
                              </a>
                            </Typography>
                          </Box> */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginY: "0.9rem",
                            }}
                          >
                            <EmailIcon
                              sx={{
                                color: "var(--button)",
                              }}
                            />
                            <Typography sx={{ marginLeft: "10px" }}>
                              {" "}
                              <a
                                className="underlined__atag"
                                href="mailto:metr@metapercept.com"
                              >
                                metr@metapercept.com
                              </a>
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <EmailIcon
                              sx={{
                                color: "var(--button)",
                              }}
                            />
                            <Typography sx={{ marginLeft: "10px" }}>
                              {" "}
                              <a
                                className="underlined__atag"
                                href="mailto:info@metapercept.com"
                              >
                                info@metapercept.com
                              </a>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </ul>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={6}>
                <ZohoForm />
              </Grid>
              {/* <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    background: "var(--contact-gradient)",
                    borderRadius: "6px",
                    paddingY: "1.5rem",
                    paddingX: "1.5rem",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="First Name"
                          name="FirstName"
                          value={formData.FirstName}
                          onChange={handleChange}
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Last Name"
                          name="LastName"
                          value={formData.LastName}
                          onChange={handleChange}
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="Email"
                          value={formData.Email}
                          onChange={handleChange}
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="What country are you located in? "
                          name="Country"
                          value={formData.Country}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <Select
                            name="CountryCode"
                            value={formData.CountryCode}
                            onChange={handleChange}
                          >
                            {codeList.map((item, index) => (
                              <MenuItem key={index} value={item.code}>
                                {item.country} (+{item.code})
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Mobile"
                          name="MobileNumber"
                          value={formData.MobileNumber}
                          onChange={handleChange}
                          variant="outlined"
                          type="number"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Company Name"
                          name="Company_name"
                          value={formData.Company_name}
                          onChange={handleChange}
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="What are the challenges you face? "
                          name="Description"
                          value={formData.Description}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            marginRight: "0px",
                          }}
                          control={
                            <Checkbox
                              name="isPolicyChecked"
                              checked={formData.isPolicyChecked}
                              onChange={handleChange}
                            />
                          }
                        />
                        <Typography sx={{ fontSize: "14px" }}>
                          Yes, I accept the{" "}
                          <a
                            href="https://metapercept.com/gdpr/termsandconditions/"
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{
                              color: "var(--text2)",
                            }}
                          >
                            terms of use
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://metapercept.com/privacy-policy/"
                            target="_blank"
                            rel="noreferrer noopener"
                            style={{
                              color: "var(--text2)",
                            }}
                          >
                            privacy policy.
                          </a>
                        </Typography>
                      </Grid>
                      {(errors || emailError || phoneError) && (
                        <Box
                          component={"div"}
                          sx={{
                            paddingX: "16px",
                          }}
                        >
                          {errors?.map((err, index) => (
                            <FormHelperText
                              key={index}
                              sx={{
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ErrorIcon sx={{ marginRight: "5px" }} />
                              {separateWords(err.message)}
                            </FormHelperText>
                          ))}
                          {emailError && (
                            <FormHelperText
                              sx={{
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ErrorIcon sx={{ marginRight: "5px" }} />
                              {emailError}
                            </FormHelperText>
                          )}
                          {phoneError && (
                            <FormHelperText
                              sx={{
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ErrorIcon sx={{ marginRight: "5px" }} />
                              {phoneError}
                            </FormHelperText>
                          )}
                        </Box>
                      )}

                      <Grid
                        item
                        xs={12}
                        sx={{
                          marginY: "1rem",
                          paddingX: "20px",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          sx={{
                            background: "var(--secondary)",
                            "&:hover": {
                              background: "var(--button-hover)",
                            },
                          }}
                        >
                          {loading ? (
                            <CircularProgress
                              color="success"
                              sx={{
                                maxWidth: "23px",
                                maxHeight: "23px",
                                color: "white",
                              }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Thanks for submitting your request. Our sales team will
                    contact you soon.
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid> */}

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                      textAlign={"center"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon
                        sx={{ fontSize: "3rem", color: "#4cb050" }}
                      />
                      Submission Successful!
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2, textAlign: "center" }}
                    >
                      Thanks for reaching out! We've received your information
                      and will review it soon. We'll be in touch shortly!
                    </Typography>

                    {/* Close Button */}
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Fade>
              </Modal>
            </Grid>
          </Box>
        </Container>
      </CustomContainer>
    </Box>
  );
}

export default ContactUs;
