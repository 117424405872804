import React, { useEffect, useState, useMemo } from "react";
import { translateLanguage } from "./google-language-selector";
import imggr from "../../assets/new/german.png";
import imgsp from "../../assets/new/spain.png";
import imen from "../../assets/new/english.png";
import imgfr from "../../assets/new/france.png";
import { Menu, MenuItem, IconButton, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function GoogleLangPicker() {
  const theme = useTheme();

  // Memoize the options array to prevent unnecessary re-renders
  const options = useMemo(
    () => [
      {
        label: "en",
        image: imen,
        language: "English",
      },
      {
        label: "es",
        image: imgsp,
        language: "Spanish",
      },
      {
        label: "de",
        image: imggr,
        language: "German",
      },
      {
        label: "fr",
        image: imgfr,
        language: "French",
      },
    ],
    []
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
    translateLanguage(option.language);
  };

  useEffect(() => {
    let lang = localStorage.getItem("currentLang");

    window.onload = function () {
      translateLanguage(lang);
    };
    let tempSelected = options.filter((option) => option.language === lang);
    if (tempSelected.length > 0) {
      selectOption(tempSelected[0]);
    }
  }, [options]);

  return (
    <>
      <Box>
        <IconButton onClick={handleClick}>
          <img
            src={selectedOption.image}
            alt={selectedOption.label}
            style={{ marginRight: 10, maxWidth: "20px", minWidth: "20px" }}
          />
          <Typography
            variant="body1"
            translate="no"
            sx={{
              color: "black",
              textTransform: "capitalize",
              [theme.breakpoints.up("md")]: {
                color: "black",
              },
            }}
          >
            {selectedOption.label.charAt(0).toUpperCase() +
              selectedOption.label.slice(1)}
          </Typography>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => selectOption(option)}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={option.image}
                alt={option.label}
                style={{ marginRight: 10, maxWidth: "20px", maxHeight: "20px" }}
              />
              <Typography
                variant="body1"
                translate="no"
                sx={{ color: "black", textTransform: "capitalize" }}
              >
                {option.label.charAt(0).toUpperCase() + option.label.slice(1)}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
}

export default GoogleLangPicker;
