import React from "react";
import { Box } from "@mui/material";

function RequestDemo({ fsize = "15px", bgColor = "var(--secondary)" }) {
  return (
    <Box
      component={"span"}
      sx={{
        color: "var(--white)",
        display: "inline-block",
        borderRadius: "100px",
        maxHeight: "40px",
        background: bgColor,
        padding: "12px 1.2rem",
        fontWeight: "400",
        fontSize: fsize,
        transition: "0.3s",
        "&:hover": {
          backgroundColor: "var(--text2)",
        },
      }}
    >
      Book a Demo
    </Box>
  );
}

export default RequestDemo;
