import React from "react";
import Banner from "../components/Banner";
import { Box } from "@mui/material";
import CustomContainer from "../components/CustomContainer";

import Features from "../components/Features/Features";
import Testimonial from "../components/Testimonial/Testimonial";
import Integrate from "../components/WorkFlow/Integrate";
// import Features2 from "../components/Features/Features2";
import Brands from "../components/Brands/Brands";

function Main() {
  return (
    <Box>
      <Box
        sx={{
          background: "var(--gradient-new)",
        }}
      >
        <CustomContainer>
          <Banner />
        </CustomContainer>
      </Box>
      <Integrate />
      <Features />
      {/* <Features2 /> */}
      <Brands />
      <Box sx={{ background: "var(--gradient-new3)" }}>
        <Testimonial />
      </Box>

      {/* <Box
        sx={{
          background: "linear-gradient(#fff 0%, #f6f6f9 100%)",
          paddingBottom: "2rem",
        }}
      >
        <Setup />
      </Box>
      <Box
        sx={{
          paddingY: "4rem",
        }}
      >
        <BenefitsNew />
      </Box> */}
      {/* <Box sx={{ display: "flex", justifyContent: "center", marginY: "2rem" }}>
        <Link
          to={"/request-demo"}
          className="requestButton"
          style={{ zIndex: "1" }}
        >
          <RequestDemo />
        </Link>
      </Box> */}
    </Box>
  );
}

export default Main;
