import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomContainer from "../CustomContainer";

const migrationPolicy = [
  {
    title: "DOCX to DITA",
    description: `
        <h3>1. Purpose:</h3>
        <p>This document outlines the policy and best practices for converting and migrating unstructured content to structured DITA-XML format. This policy ensures consistency, efficiency, and accuracy in the migration process.</p>

        <h3>2. File Naming Convention:</h3>
        <ul>
            <li>Use descriptive and concise file names that reflect the content of the document.</li>
            <li>Avoid special characters, spaces, and symbols in file names.</li>
            <li>Follow a consistent naming convention.</li>
        </ul>
        <p><strong>Example:</strong></p>
        <ul>
            <li><code>[Document_Title]_[Version_Number].docx</code></li>
            <li><code>[Document_Title]_[Version_Number].html</code></li>
            <li><code>index.md</code> or <code>index.mdx</code></li>
        </ul>

        <h3>3. File Name Length:</h3>
        <p>Limit file names to 255 characters to ensure compatibility with file systems and avoid truncation issues.</p>

        <h3>4. Folder Structure:</h3>
        <p>Maintain consistency in folder naming and hierarchy across projects.</p>

        <h3>5. Images Folder:</h3>
        <ul>
            <li>Create a designated "Images" folder within the project directory to store all image files referenced in the input source documents.</li>
            <li>Ensure that image file names are descriptive and follow the same naming conventions as the input source files.</li>
        </ul>

        <h3>6. Cross-References:</h3>
        <p>metR uses a generic logic to retain all the cross-references during the conversion process. However, store cross-references and links in a separate mapping file to facilitate easy tracking and management post-conversion.</p>
        <p>Use relative paths for cross-references to ensure portability and maintainability of documents.</p>

        <h3>7. Document Metadata:</h3>
        <ul>
            <li>The input source files should include relevant metadata such as author, date created, date modified, and version number.</li>
            <li>Ensure consistency in metadata formatting and placement across all documents.</li>
        </ul>
    `,
  },
  {
    title: "Markdown to DITA",
    description: `
        <h3>1. Purpose:</h3>
        <p>This document outlines the policy and best practices for converting and migrating unstructured content to structured DITA-XML format. This policy ensures consistency, efficiency, and accuracy in the migration process.</p>

        <h3>2. File Naming Convention:</h3>
        <ul>
            <li>Use descriptive and concise file names that reflect the content of the document.</li>
            <li>Avoid special characters, spaces, and symbols in file names.</li>
            <li>Follow a consistent naming convention.</li>
        </ul>
        <p><strong>Example:</strong></p>
        <ul>
            <li><code>[Document_Title]_[Version_Number].docx</code></li>
            <li><code>[Document_Title]_[Version_Number].html</code></li>
            <li><code>index.md</code> or <code>index.mdx</code></li>
        </ul>

        <h3>3. File Name Length:</h3>
        <p>Limit file names to 255 characters to ensure compatibility with file systems and avoid truncation issues.</p>

        <h3>4. Folder Structure:</h3>
        <p>Maintain consistency in folder naming and hierarchy across projects.</p>

        <h3>5. Images Folder:</h3>
        <ul>
            <li>Create a designated "Images" folder within the project directory to store all image files referenced in the input source documents.</li>
            <li>Ensure that image file names are descriptive and follow the same naming conventions as the input source files.</li>
        </ul>

        <h3>6. Cross-References:</h3>
        <p>metR uses a generic logic to retain all the cross-references during the conversion process. However, store cross-references and links in a separate mapping file to facilitate easy tracking and management post-conversion.</p>
        <p>Use relative paths for cross-references to ensure portability and maintainability of documents.</p>

        <h3>7. Document Metadata:</h3>
        <ul>
            <li>The input source files should include relevant metadata such as author, date created, date modified, and version number.</li>
            <li>Ensure consistency in metadata formatting and placement across all documents.</li>
        </ul>
    `,
  },
  {
    title: "HTML to DITA",
    description: `
    <h3>1. Purpose:</h3>
    <p>This document outlines the policy and best practices for converting and migrating unstructured content to structured DITA-XML format. This policy ensures consistency, efficiency, and accuracy in the migration process.</p>

    <h3>2. File Naming Convention:</h3>
    <ul>
        <li>Use descriptive and concise file names that reflect the content of the document.</li>
        <li>Avoid special characters, spaces, and symbols in file names.</li>
        <li>Follow a consistent naming convention.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><code>[Document_Title]_[Version_Number].docx</code></li>
        <li><code>[Document_Title]_[Version_Number].html</code></li>
        <li><code>index.md</code> or <code>index.mdx</code></li>
    </ul>

    <h3>3. File Name Length:</h3>
    <p>Limit file names to 255 characters to ensure compatibility with file systems and avoid truncation issues.</p>

    <h3>4. Folder Structure:</h3>
    <p>Maintain consistency in folder naming and hierarchy across projects.</p>

    <h3>5. Images Folder:</h3>
    <ul>
        <li>Create a designated "Images" folder within the project directory to store all image files referenced in the input source documents.</li>
        <li>Ensure that image file names are descriptive and follow the same naming conventions as the input source files.</li>
    </ul>

    <h3>6. Cross-References:</h3>
    <p>metR uses a generic logic to retain all the cross-references during the conversion process. However, store cross-references and links in a separate mapping file to facilitate easy tracking and management post-conversion.</p>
    <p>Use relative paths for cross-references to ensure portability and maintainability of documents.</p>

    <h3>7. Document Metadata:</h3>
    <ul>
        <li>The input source files should include relevant metadata such as author, date created, date modified, and version number.</li>
        <li>Ensure consistency in metadata formatting and placement across all documents.</li>
    </ul>
`,
  },
  {
    title: "PDF to DITA",
    description: `
        <h3>1. Purpose:</h3>
        <p>This document outlines the policy and best practices for converting and migrating unstructured content to structured DITA-XML format. This policy ensures consistency, efficiency, and accuracy in the migration process.</p>

        <h3>2. File Naming Convention:</h3>
        <ul>
            <li>Use descriptive and concise file names that reflect the content of the document.</li>
            <li>Avoid special characters, spaces, and symbols in file names.</li>
            <li>Follow a consistent naming convention.</li>
        </ul>
        <p><strong>Example:</strong></p>
        <ul>
            <li><code>[Document_Title]_[Version_Number].docx</code></li>
            <li><code>[Document_Title]_[Version_Number].html</code></li>
            <li><code>index.md</code> or <code>index.mdx</code></li>
        </ul>

        <h3>3. File Name Length:</h3>
        <p>Limit file names to 255 characters to ensure compatibility with file systems and avoid truncation issues.</p>

        <h3>4. Folder Structure:</h3>
        <p>Maintain consistency in folder naming and hierarchy across projects.</p>

        <h3>5. Images Folder:</h3>
        <ul>
            <li>Create a designated "Images" folder within the project directory to store all image files referenced in the input source documents.</li>
            <li>Ensure that image file names are descriptive and follow the same naming conventions as the input source files.</li>
        </ul>

        <h3>6. Cross-References:</h3>
        <p>metR uses a generic logic to retain all the cross-references during the conversion process. However, store cross-references and links in a separate mapping file to facilitate easy tracking and management post-conversion.</p>
        <p>Use relative paths for cross-references to ensure portability and maintainability of documents.</p>

        <h3>7. Document Metadata:</h3>
        <ul>
            <li>The input source files should include relevant metadata such as author, date created, date modified, and version number.</li>
            <li>Ensure consistency in metadata formatting and placement across all documents.</li>
        </ul>
    `,
  },
  {
    title: "FrameMaker to DITA",
    description: `
        <h3>1. Purpose:</h3>
        <p>This document outlines the policy and best practices for converting and migrating unstructured content to structured DITA-XML format. This policy ensures consistency, efficiency, and accuracy in the migration process.</p>

        <h3>2. File Naming Convention:</h3>
        <ul>
            <li>Use descriptive and concise file names that reflect the content of the document.</li>
            <li>Avoid special characters, spaces, and symbols in file names.</li>
            <li>Follow a consistent naming convention.</li>
        </ul>
        <p><strong>Example:</strong></p>
        <ul>
            <li><code>[Document_Title]_[Version_Number].docx</code></li>
            <li><code>[Document_Title]_[Version_Number].html</code></li>
            <li><code>index.md</code> or <code>index.mdx</code></li>
        </ul>

        <h3>3. File Name Length:</h3>
        <p>Limit file names to 255 characters to ensure compatibility with file systems and avoid truncation issues.</p>

        <h3>4. Folder Structure:</h3>
        <p>Maintain consistency in folder naming and hierarchy across projects.</p>

        <h3>5. Images Folder:</h3>
        <ul>
            <li>Create a designated "Images" folder within the project directory to store all image files referenced in the input source documents.</li>
            <li>Ensure that image file names are descriptive and follow the same naming conventions as the input source files.</li>
        </ul>

        <h3>6. Cross-References:</h3>
        <p>metR uses a generic logic to retain all the cross-references during the conversion process. However, store cross-references and links in a separate mapping file to facilitate easy tracking and management post-conversion.</p>
        <p>Use relative paths for cross-references to ensure portability and maintainability of documents.</p>

        <h3>7. Document Metadata:</h3>
        <ul>
            <li>The input source files should include relevant metadata such as author, date created, date modified, and version number.</li>
            <li>Ensure consistency in metadata formatting and placement across all documents.</li>
        </ul>
    `,
  },
  {
    title: "JSON to DITA",
    description: `
    <h3>1. Purpose:</h3>
    <p>This document outlines the policy and best practices for converting and migrating unstructured content to structured DITA-XML format. This policy ensures consistency, efficiency, and accuracy in the migration process.</p>

    <h3>2. File Naming Convention:</h3>
    <ul>
        <li>Use descriptive and concise file names that reflect the content of the document.</li>
        <li>Avoid special characters, spaces, and symbols in file names.</li>
        <li>Follow a consistent naming convention.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><code>[Document_Title]_[Version_Number].docx</code></li>
        <li><code>[Document_Title]_[Version_Number].html</code></li>
        <li><code>index.md</code> or <code>index.mdx</code></li>
    </ul>

    <h3>3. File Name Length:</h3>
    <p>Limit file names to 255 characters to ensure compatibility with file systems and avoid truncation issues.</p>

    <h3>4. Folder Structure:</h3>
    <p>Maintain consistency in folder naming and hierarchy across projects.</p>

    <h3>5. Images Folder:</h3>
    <ul>
        <li>Create a designated "Images" folder within the project directory to store all image files referenced in the input source documents.</li>
        <li>Ensure that image file names are descriptive and follow the same naming conventions as the input source files.</li>
    </ul>

    <h3>6. Cross-References:</h3>
    <p>metR uses a generic logic to retain all the cross-references during the conversion process. However, store cross-references and links in a separate mapping file to facilitate easy tracking and management post-conversion.</p>
    <p>Use relative paths for cross-references to ensure portability and maintainability of documents.</p>

    <h3>7. Document Metadata:</h3>
    <ul>
        <li>The input source files should include relevant metadata such as author, date created, date modified, and version number.</li>
        <li>Ensure consistency in metadata formatting and placement across all documents.</li>
    </ul>
`,
  },
];

function ConPolicy() {
  return (
    <Box
      component="div"
      sx={{
        marginBottom: "4rem",
      }}
    >
      <Box
        component={"div"}
        sx={{
          paddingTop: "2rem",
          paddingBottom: "4rem",
          background: "var(--gradient-color)",
        }}
      >
        <CustomContainer>
          <Container>
            <Typography
              align="center"
              sx={{
                fontSize: "42px",
                fontWeight: "700",
                color: "var(--text-primary)",
              }}
            >
              Best Practices
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "var(--text-gray)",
                paddingY: "1rem",
              }}
            >
              Document Conversion policy
            </Typography>
          </Container>
        </CustomContainer>
      </Box>
      <CustomContainer>
        <Container>
          <Box component={"div"} sx={{ marginBottom: "2rem" }}>
            {migrationPolicy?.map((queAns, index) => (
              <Accordion
                key={index}
                sx={{
                  background: "var(--white)",
                  // border: "1px solid var(--border)",
                  padding: "4px 16px",
                  marginBottom: "1rem",
                  borderRadius: "4px",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",

                  "&::before": {
                    backgroundColor: "rgba(0, 0, 0, 0)",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: "var(--secondary)" }} />
                  }
                  aria-controls="panel-content"
                  id="panel-header"
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      fontWeight: "600",
                      margin: "0",
                    }}
                  >
                    {queAns.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingTop: "0",
                  }}
                >
                  <div
                    className="accordion_desc"
                    style={{ fontSize: "16px !important", fontWeight: "400" }}
                    dangerouslySetInnerHTML={{
                      __html: queAns.description,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </CustomContainer>
    </Box>
  );
}

export default ConPolicy;
