import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import start from "../../assets/start.png";
import management_2 from "../../assets/management_2.png";
import enterprise from "../../assets/enterprise.png";

const plans = [
  {
    id: 1,
    image: start,
    title: "Starter Plan",
    subtitle: "For small teams starting their structured content journey",
    backgroundColor: "#F3F8FC",
    color: "#000",
    shortDescription:
      "The Starter Plan is perfect for teams still working with unstructured formats like DOCX, InDesign-PDF, or HTML. It solves the challenge of version control across releases and helps teams transition to a structured, scalable content model.",
    whatIncluded: `
      <h4>What’s included</h4>
<ul>
<li>3,000-page processing credit</li>
<li>One-time content modeling service</li>
</ul>

With metR, you can confidently convert your content to DITA, paving the way for efficient, reusable, and AI-ready content workflows.
    `,
  },
  {
    id: 2,
    image: management_2,
    title: "Teams Plan",
    subtitle: "For growing teams ready to scale with DITA",
    backgroundColor: "#E8F5E9",
    color: "#000",
    shortDescription:
      "The Teams Plan is built for organizations already experimenting with DITA or preparing to adopt it. It offers a smart alternative to investing heavily in a CCMS or custom development, making structured content accessible and scalable.",
    whatIncluded: `
      <h4>What’s included</h4>

<ul>
<li>7,000-page processing credit</li>
<li>One-time content modeling service</li>
<li>One-time output design for PDF, HTML, or a branded Web Portal</li>
<li>Dedicated instance of metR with custom integration support</li>
<li>Priority customer support</li>
</ul>

This plan is ideal for teams needing advanced content modeling, higher throughput, and cloud-ready publishing options.
    `,
  },
  {
    id: 3,
    image: enterprise,
    title: "Enterprise Plan",
    subtitle:
      "For enterprise teams managing complex, AI-enabled content operations",
    backgroundColor: "#FFF3E0",
    color: "#000",
    shortDescription:
      "The Enterprise Plan is tailored for large technical publication teams working with conditional tagging, white-labeled documentation, and multiple content variants. Built for those already using DITA and version control tools like GitHub, it supports advanced content transformation, AI enablement, and CCMS integration.",
    whatIncluded: `
      <h4>What’s included</h4>

<ul>
<li>Support for 10,000+ pages of technical documentation</li>
<li>Multi-team collaboration support</li>
<li>Custom DITA plugin development for white-labeled outputs</li>
<li>Tools to build and train custom LLMs on your DITA content</li>
<li>Enterprise-level AI integration and metadata modeling</li>
<li>Semantic search and advanced retrieval capabilities</li>
</ul>

This plan empowers enterprises to automate, personalize, and scale content delivery while harnessing the full potential of structured authoring and AI.
    
    `,
  },
];

function NewPlan() {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start">
      {plans.map((plan) => (
        <Grid
          item
          key={plan.id}
          xs={12}
          md={5}
          lg={4}
          sx={{ alignSelf: "flex-start" }}
        >
          <Card
            sx={{
              maxWidth: 500,
              backgroundColor: plan.backgroundColor,
              textAlign: "center",
              borderRadius: 3,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
              gap: 2,
              height: "auto",
              minHeight: "450px",
            }}
          >
            <CardMedia
              component="img"
              src={plan.image}
              alt={plan.title}
              sx={{ width: 100, height: "auto", objectFit: "contain", mt: 2 }}
            />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                gap: 0.5,

                padding: "0",
                paddingBottom: "0 !important",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{ pb: "10px", fontSize: "24px", fontWeight: 600 }}
                >
                  {plan.title}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ pb: "10px", fontSize: "17px", fontWeight: 600 }}
                >
                  {plan.subtitle}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {plan.shortDescription}
                </Typography>
                {expanded[plan.id] && (
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    dangerouslySetInnerHTML={{ __html: plan.whatIncluded }}
                  />
                )}
              </Box>

              <Button
                variant="text"
                sx={{
                  mt: 2,
                  maxWidth: "200px",
                  mx: "auto",
                  color: "var(--text2)",
                }}
                onClick={() => toggleExpand(plan.id)}
              >
                {expanded[plan.id] ? "Read Less" : "Read More"}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default NewPlan;
