import { Box, Typography } from "@mui/material";

const Brands = () => {
  return (
    <Box
      mt={4}
      sx={{
        background:
          "linear-gradient(278.67deg, #9454FE -11.63%, #4D5EF6 54.62%, #2BCCFF 120.87%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: { xs: 3, md: 5 },
        px: { xs: 2, md: 4 },
      }}
    >
      <Typography
        fontWeight="600"
        sx={{
          color: "white",
          textAlign: "center",
          textTransform: "uppercase",

          fontSize: {
            xs: "25px",
            sm: "30px",
            md: "43px",
            lg: "40px",
          },

          lineHeight: {
            xs: "40px",
            sm: "40px",
            md: "45px",
            lg: "50px",
          },
        }}
      >
        Trusted by global businesses
      </Typography>
    </Box>
  );
};

export default Brands;
