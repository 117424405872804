import React from "react";
import { Box, Container, ListItemIcon, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import CustomContainer from "../components/CustomContainer";
import logo from "../assets/logo.svg";
import nasscom_img from "../assets/new/nasscom_img.png";
import iso1 from "../assets/new/iso1.png";
import iso2 from "../assets/new/iso2.png";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";

function Footer() {
  return (
    <Box
      component={"footer"}
      position="static"
      sx={{
        background: "var(--gradient-new)",
        boxShadow: "none",
      }}
    >
      <Box component={"div"}>
        <CustomContainer>
          <Container>
            <Box sx={{ pt: 5, pb: 3 }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <ListItemIcon component="div">
                    <Link to={"/"}>
                      <img src={logo} alt="" srcSet="" />
                    </Link>
                  </ListItemIcon>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ mt: 0.5, marginBottom: 2 }}
                    >
                      Subscribe with us
                    </Typography>
                    <form
                      method="post"
                      action="https://metapercept.us7.list-manage.com/subscribe/post?u=8b858eea4117668a03131d383&amp;id=ebad419a48&amp;f_id=0020c4e4f0"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      target="_blank"
                      style={{
                        display: "flex",
                        border: "1px solid var(--border)",
                        borderRadius: "3px",
                      }}
                      onSubmit={(e) => {
                        setTimeout(() => {
                          e.target.reset(); // Clear form fields after submission
                        }, 100); // Timeout ensures submission happens first
                      }}
                    >
                      <input
                        type="email"
                        placeholder="Enter your email"
                        id="EMAIL"
                        name="EMAIL"
                        autoComplete="off"
                        required
                        data-required="true"
                        style={{
                          border: "none",
                          outline: "none",
                          padding: "10px 5px",
                          width: "100%",
                          backgroundColor: "transparent",
                        }}
                      />
                      <button
                        type="submit"
                        aria-label="subscribe"
                        style={{
                          outline: "none",
                          border: "none",
                          backgroundColor: "var(--secondary)",
                          color: "white",
                          cursor: "pointer",
                          borderTopRightRadius: "3px",
                          borderBottomRightRadius: "3px",
                        }}
                      >
                        Subscribe
                      </button>
                    </form>

                  </Box>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography
                    fontSize={"16px"}
                    fontWeight="bold"
                    marginBottom={2}
                  >
                    Quick Links
                  </Typography>

                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://metapercept.com/aboutus/"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          About us
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://metapercept.com/services/"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          Services
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://metapercept.com/solutions/"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          Solutions
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://metapercept.com/contact/"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          Contact Us
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://blog.metapercept.com/"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          Blogs
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://blog.metapercept.com/blog/category/events"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          Events
                        </Link>
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://blog.metapercept.com/blog/category/news"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          News
                        </Link>
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ mt: 0.5 }}>
                        <Link
                          className="footer-link"
                          to="https://metapercept.com/aboutus/terminologies/"
                          target="_blank"
                          color="inherit"
                          underline="hover"
                        >
                          Glossary
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography
                    fontSize={"16px"}
                    fontWeight="bold"
                    marginBottom={2}
                  >
                    Contact Details
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                    <PhoneInTalkIcon
                      fontSize="10px"
                      style={{ marginRight: "5px" }}
                    />
                    <Link
                      className="footer-link"
                      to="tel:8390905726"
                      color="inherit"
                      underline="hover"
                    >
                      +91-839-090-5726
                    </Link>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                    <EmailIcon fontSize="10px" style={{ marginRight: "5px" }} />
                    <Link
                      className="footer-link"
                      to="mailto:sales@metapercept.com"
                      color="inherit"
                      underline="hover"
                    >
                      sales@metapercept.com
                    </Link>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
                    <EmailIcon fontSize="10px" style={{ marginRight: "5px" }} />
                    <Link
                      className="footer-link"
                      to="mailto:info@metapercept.com"
                      color="inherit"
                      underline="hover"
                    >
                      info@metapercept.com
                    </Link>
                  </Box>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography
                    fontSize={"16px"}
                    fontWeight="bold"
                    marginBottom={2}
                  >
                    Offices
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 0.5, mb: 2, fontSize: "13px" }}
                  >
                    India Office: <br /> Pune, Maharashtra, India
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    sx={{ my: 0.5, fontSize: "13px" }}
                  >
                    USA Office: <br />
                    Arlington, Texas, USA
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2}>
                  <Typography
                    fontSize={"16px"}
                    fontWeight="bold"
                    marginBottom={2}
                  >
                    Social Links
                  </Typography>

                  <Box>
                    <Box>
                      <Link
                        style={{ marginRight: "12px" }}
                        to="https://www.facebook.com/metapercepttechservices/"
                        target="_blank"
                        color="inherit"
                        underline="hover"
                      >
                        <FacebookIcon
                          sx={{
                            fontSize: "1.9rem",
                            transition: "color 0.3s",
                            "&:hover": {
                              color: "var(--secondary)",
                            },
                          }}
                        />
                      </Link>

                      <Link
                        to="https://twitter.com/MetaPercept"
                        target="_blank"
                        color="inherit"
                        underline="hover"
                      >
                        <XIcon
                          sx={{
                            fontSize: "1.75rem",
                            transition: "color 0.3s",
                            "&:hover": {
                              color: "var(--secondary)",
                            },
                          }}
                        />
                      </Link>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Link
                        style={{ marginRight: "12px" }}
                        to="https://www.linkedin.com/showcase/metrdocumentpublishingtool/"
                        color="inherit"
                        underline="hover"
                        target="_blank"
                      >
                        <LinkedInIcon
                          sx={{
                            fontSize: "1.9rem",
                            transition: "color 0.3s",
                            "&:hover": {
                              color: "var(--secondary)",
                            },
                          }}
                        />
                      </Link>

                      <Link
                        to="https://blog.metapercept.com/"
                        target="_blank"
                        color="inherit"
                        underline="hover"
                      >
                        <RssFeedIcon
                          sx={{
                            fontSize: "2rem",
                            transition: "color 0.3s",
                            "&:hover": {
                              color: "var(--secondary)",
                            },
                          }}
                        />
                      </Link>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography
                    fontSize={"16px"}
                    fontWeight="bold"
                    marginBottom={2}
                  >
                    Accreditations
                  </Typography>
                  <Box>
                    <img
                      style={{ maxWidth: "90px" }}
                      src={nasscom_img}
                      alt=""
                    />

                    <Box mt={2}>
                      <img
                        style={{ width: "35px", marginRight: "10px" }}
                        src={iso1}
                        alt=""
                      />
                      <img style={{ width: "35px" }} src={iso2} alt="" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* Footer Bottom Section */}
              <Box
                sx={{
                  textAlign: "center",
                  mt: 4,
                  pt: 3,
                  borderTop: "1px solid black",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { xs: "center", md: "center" },
                  }}
                >
                  {/* Copyright Text */}
                  <Typography variant="body2" sx={{ fontSize: "13px" }}>
                    Copyright © 2025{" "}
                    <Link
                      className="footer-link"
                      to="https://metapercept.com/"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ cursor: "pointer" }}
                    >
                      Metapercept Technology Services LLP
                    </Link>{" "}
                    All Rights Reserved
                  </Typography>

                  {/* Privacy Policy & Terms (Stacked on Mobile) */}
                  <Box
                    sx={{
                      mt: { xs: 1, md: 0 },
                      textAlign: { xs: "center", md: "right" },
                    }}
                  >
                    <Link
                      className="footer-link"
                      sx={{
                        display: "block",
                        mb: { xs: 1, md: 0 },
                        mr: { md: 2 },
                      }}
                      style={{ marginRight: "10px" }}
                      to="https://metapercept.com/privacy-policy/"
                      target="_blank"
                      color="inherit"
                      underline="hover"
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      component="a"
                      className="footer-link"
                      sx={{ display: "block" }}
                      to="http://metapercept.com/gdpr/termsandconditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      underline="hover"
                    >
                      Terms of Service
                    </Link>

                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </CustomContainer>
      </Box>
    </Box>
  );
}

export default Footer;
