import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import sectionHeroHeader from "../../assets/home/mar9-outline.jpg";

import CustomContainer from "../CustomContainer";

import DatasetIcon from "../../assets/icons/metadata.png";
import RocketLaunchIcon from "../../assets/icons/shuttle.png";
import EmojiObjectsIcon from "../../assets/icons/inspiration.png";
import PsychologyIcon from "../../assets/icons/bot.png";

const features = [
  {
    title: "AI-Based Conversion",
    description:
      "Automatically turn Word, PDF, HTML, FrameMaker, Markdown, or JSON files into clean DITA-XML format for structured authoring and efficient content reuse.",
    icon: PsychologyIcon,
  },
  {
    title: "Metadata Enrichment",
    description:
      "Enhance and organize content with AI-driven metadata and tagging. metR extracts key information to improve content classification, searchability, and retrieval.",
    icon: DatasetIcon,
  },
  {
    title: "Instant Content Delivery",
    description:
      "Publish or distribute structured content seamlessly. Push updates to knowledge bases, CCMS platforms, portals, and AI chatbots with automated workflows.",
    icon: RocketLaunchIcon,
  },
  {
    title: "Tailored Content Solutions",
    description:
      "Convert and enrich content across industries—from manufacturing to medical, finance to software—ensuring precision and compliance with domain-specific standards.",
    icon: EmojiObjectsIcon,
  },
];

const Features = () => {
  const theme = useTheme();
  return (
    <CustomContainer>
      <Container>
        <Box
          component={"div"}
          sx={{
            paddingTop: { xs: "2rem", md: "5rem" },
            paddingBottom: { xs: "2rem", md: "3rem" },
            [theme.breakpoints.up("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Typography
            variant="h2"
            textAlign={{ xs: "center", lg: "left" }}
            sx={{
              fontWeight: "600",
              color: "var(--black)",
              fontSize: {
                xs: "25px",
                sm: "30px",
                md: "43px",
                lg: "40px",
              },

              lineHeight: {
                xs: "40px",
                sm: "40px",
                md: "45px",
                lg: "50px",
              },

              [theme.breakpoints.down("md")]: {
                textAlign: "center",
                marginTop: "2rem",
              },
            }}
          >
            Core Features Overview
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "center",

              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
              },
            }}
          >
            <Grid
              item
              md={6}
              sx={{
                overflow: "hidden",
                position: "relative",
                height: "100%",
              }}
            >
              <Box sx={{ marginTop: "3rem" }}>
                <Grid container spacing={{ xs: 6, md: 2 }}>
                  {features.map((feature, index) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: { xs: "center", md: "flex-start" },
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            marginRight: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "36px",
                            height: "36px",
                            background: "var(--secondary)",
                            borderRadius: "100%",
                            color: "var(--white)",
                          }}
                        >
                          <img
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                            src={feature.icon}
                            alt=""
                          />
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "500",
                            fontSize: "20px",
                            color: "var(--black)",
                            textAlign: { xs: "center", md: "left" },
                          }}
                        >
                          {feature.title}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          marginTop: "1rem",
                          color: "var(--text-new)",
                          fontWeight: "400",
                          fontSize: "14px",
                          textAlign: { xs: "center", md: "left" },
                          maxWidth: "600px",
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              mt={{ xs: "2rem", md: "0" }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                style={{ borderRadius: "5px" }}
                src={sectionHeroHeader}
                alt=""
                srcSet=""
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </CustomContainer>
  );
};

export default Features;
