import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Main from "./pages/Main";

import SolutionPage from "./pages/SolutionPage";
import ContactUs from "./pages/ContactUs";
import { useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import RequestPage from "./pages/RequestPage";

// import TryNowPublisher from "./pages/TryNowPublisher";
// import TryNowMigration from "./pages/TryNowMigration";
// import TryNow from "./pages/TryNow";
// import ResponsiveModal from "./components/ROICalculator/ResponsiveModal";
// import Faq from "./pages/Faq";

import PageNotFound from "./pages/404";
import ReactGA from "react-ga4";
import PricingPlan from "./components/Buy/PricingPlan";
import FAQ from "./components/SolutionPage/FAQ";
import ConPolicy from "./components/SolutionPage/ConPolicy";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const location = useLocation();
  const TRACKING_ID = "G-QWWV3LTS7D";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>DOCX HTML Markdown Converter Tool - metR</title>
                <meta
                  name="description"
                  content="Convert unstructured DOCX, HTML, and Markdown content to DITA-XML to improve your content management challenges using topic-based and single-sourced content."
                />
                <meta
                  name="keywords"
                  content="Unstructured Content Converter, DOCX to DITA, HTML to DITA, Markdown to DITA, DITA-OT Plugin builder, unstructured to DITA conversion, DITA-OT plugin for PDF, DITA-OT plugin for HTML, Static Site Generation using CD/CI"
                />
              </Helmet>
              <Main />
            </>
          }
        />
        <Route
          path="/solutions"
          element={
            <>
              <Helmet>
                <title>Convert unstructured document to DITA-XML</title>
                <meta
                  name="description"
                  content="Unstructured document conversion using metR increases efficiency, productivity, and return-on-investment."
                />
                <meta
                  name="keywords"
                  content="docx to dita conversion, html to dita conversion, markdown to dita conversion, DITA-OT PDF publisher, DITA-OT HTML output publisher, GitHub for DITA content repository, technical writing, technical documentation"
                />
              </Helmet>
              <SolutionPage />
            </>
          }
        />
        <Route
          path="/contact-us"
          element={
            <>
              <Helmet>
                <title>Unstructured document conversion to DITA-XML</title>
                <meta
                  name="description"
                  content="DITA-XML solution provider in India."
                />
                <meta
                  name="keywords"
                  content="Best unstructured technical document conversion toolkit."
                />
              </Helmet>
              <ContactUs />
            </>
          }
        />
        <Route
          path="/packages"
          element={
            <>
              <Helmet>
                <title>
                  Cost effective unstructured content to dita conversion tool
                </title>
                <meta
                  name="description"
                  content="Minimize your cost for converting unstructured content to DITA using metR. Effortless document conversion to DITA for small, medium, and enterprise technical documents."
                />
                <meta
                  name="keywords"
                  content="Low cost unstructured to DITA conversion toolkit."
                />
              </Helmet>
              <PricingPlan />
            </>
          }
        />

        <Route path="/resources" element={<Navigate to="/resources/faq" />} />
        <Route path="/resources/faq" element={<FAQ />} />
        <Route path="/resources/best-practices" element={<ConPolicy />} />

        {/* <Route path="/faq" element={<Faq />} /> */}
        {/* <Route path="/try-now" element={<TryNow />} /> */}
        {/* <Route path="/roi-calculator" element={<ResponsiveModal />} />
        <Route path="/try-now-publisher" element={<TryNowPublisher />} />
        <Route path="/try-now-migration" element={<TryNowMigration />} /> */}
        <Route path="/request-demo" element={<RequestPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
