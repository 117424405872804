import React, { useState } from "react";
import CustomContainer from "../CustomContainer";
import { Box, Container, Grid, Typography } from "@mui/material";
import playIcon from "../../assets/new/icon-next.svg";

import user1 from "../../assets/new/testomonial/feedback1.png";
import user2 from "../../assets/new/testomonial/feedback2.png";
import user3 from "../../assets/new/testomonial/feedback3.png";

const reviews = [
  {
    name: "O-I",
    title: "Vanessa Ayestaran — Senior Documentation Manager",
    quote:
      "metR by Metapercept made our transition from DOCX to DITA seamless. With Metapercept’s expert information architecture and taxonomy services, we now have structured, searchable, and future-ready content. Our documentation is cleaner, more consistent, and ready for AI integration.",
    userImg: user1,
  },
  {
    name: "Gutor",
    title: "Rebecka Nyqvist - Senior Technical Writer",
    quote:
      "Migrating from ArborText in PTC Windchill to standard DITA-XML felt complex—until we worked with Metapercept. Their team streamlined the transition, making moving our content into Heretto CCMS easy without disrupting our workflow. Now, our DITA source is cleaner, standardized, and future-proof.",
    userImg: user2,
  },
  {
    name: "Conga",
    title: "Anand Mehta - Documentation Manager",
    quote:
      "Metapercept helped us seamlessly convert our Atlassian Confluence content to DITA-XML for smooth ingestion and publishing within Heretto CCMS. Their team not only handled the bulk migration but also ensured our delta release updates were consistently converted and delivered on time. It’s been a game-changer for our content operations.",

    userImg: user3,
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  return (
    <CustomContainer>
      <Container>
        <Box component={"div"} sx={{ paddingY: "4rem" }}>
          <Typography
            width={"100%"}
            variant="h2"
            textAlign={{ xs: "center", lg: "left" }}
            sx={{
              marginBottom: { xs: "2rem", lg: "0" },
              fontWeight: "600",
              color: "var(--black)",
              fontSize: {
                xs: "25px",
                sm: "30px",
                md: "43px",
                lg: "40px",
              },
              lineHeight: {
                xs: "40px",
                sm: "40px",
                md: "45px",
                lg: "50px",
              },
            }}
          >
            Client Testimonials & Success Metrics
          </Typography>
          <Grid
            container
            spacing={2}
            display={"flex"}
            justifyContent="center"
            sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          >
            <Grid item md={6} sx={{ overflow: "hidden", position: "relative" }}>
              <Box sx={{ marginTop: { xs: "0", md: "4rem" } }}>
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  {reviews[currentIndex].name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--text-new)",
                    fontSize: "16px",
                    marginBottom: "1rem",
                    fontStyle: "italic",
                  }}
                >
                  {reviews[currentIndex].title}
                </Typography>
                <Typography variant="body1" sx={{ color: "var(--text-black)" }}>
                  “{reviews[currentIndex].quote}”
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "var(--text-black)", marginTop: "10px" }}
                >
                  {reviews[currentIndex].impact}
                </Typography>
                <Box
                  sx={{
                    marginTop: "2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={playIcon}
                    className="previous"
                    onClick={handlePrevious}
                    style={{
                      cursor: "pointer",
                      maxWidth: "40px",
                      marginRight: "12px",
                      transform: "scaleX(-1)",
                      opacity: "0.7",
                    }}
                    alt="Previous"
                  />
                  <img
                    src={playIcon}
                    className="next"
                    onClick={handleNext}
                    style={{
                      cursor: "pointer",
                      maxWidth: "40px",
                      marginRight: "10px",
                    }}
                    alt="Next"
                  />
                  <Typography
                    sx={{
                      backgroundImage: "var(--gradient-new2)",
                      color: "transparent",
                      backgroundClip: "text",
                    }}
                    variant="body1"
                  >
                    {currentIndex + 1} of {reviews.length}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: { xs: "100%", mg: "380px" },
                padding: "1rem",
              }}
            >
              <Box
                component="img"
                src={reviews[currentIndex].userImg}
                alt={reviews[currentIndex].name}
                sx={{
                  height: "100%",
                  maxWidth: { xs: "100%", lg: "500px" },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </CustomContainer>
  );
};

export default Testimonial;
